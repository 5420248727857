import Pagination from '../Pagination'
import Sort from '../SortReq'

export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    businessUnit: other.businessUnit || null,
    orgIds: other.orgIds || null,
    type: other.type || null,
    account: other.account || null,
    status: other.status || null,
    realType: other.realType || null,
    approved: other.approved || null,
    userIds: other.userIds || [],
    action: other.action || null,
    maxAmount: other.maxAmount || null,
    minAmount: other.minAmount || null,
    isFtd: other.isFtd || null,
    isStd: other.isStd || null,
    isTest: other.isTest || null,
    // registrationTime: other.registrationTime || {start: null, end: null},

    //Search
    search: other.search || '',
    clientDetails: other.clientDetails || {},
    pagination: other.pagination || Pagination(),
    sort: other.sort || Sort()
  }
}

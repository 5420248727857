<template>
  <div>
    <b-button class="mr-1" variant="primary" size="sm" @click="openModal">
      <i class="fa-solid fa-hand-holding-dollar"></i>
      {{ $t('monetary.addTransaction') }}
    </b-button>
    <b-button v-if="hasMultipleAccounts" class="mr-1" variant="dark" size="sm" @click="openBetweenAccountModal">
      <i class="fa-solid fa-exchange"></i>
      {{ $t('monetary.moveMoneyBetweenAccounts') }}
    </b-button>
    <div class="d-flex justify-content-between align-items-center">
      <!-- Total Transactions Count in the second row -->
      <div class="mb-2 mt-2">
        <span>{{ typeText }}: {{ convertToEURO(totalTransactions) }}</span>
      </div>
      <div>
        <b-button class="mr-1" :variant="type == null ? 'info' : 'outline-info' " @click="showAllTransactions"
                  size="sm"
        >{{ $t('monetary.allTransactions') }}
        </b-button>
        <b-button class="mr-1" :variant="type === 'DEPOSIT' ? 'success' : 'outline-success'" @click="showDeposits"
                  size="sm"
        >{{ $t('monetary.deposits') }}
        </b-button>
        <b-button :variant="type === 'WITHDRAWAL' ? 'warning' : 'outline-warning' " @click="showWithdraws" size="sm">
          {{ $t('monetary.withdrawals') }}
        </b-button>
      </div>
    </div>
    <div v-bind:key="refreshKey">
      <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
      <b-table responsive
               v-bind:items="monetaries"
               v-bind:fields="fields"
               no-local-sorting
               v-bind:sort-by.sync="sort.field"
               v-bind:sort-desc.sync="sortIsDesc"
               striped
               hover
               @sort-changed="onSortChanged"
               style="white-space: nowrap; padding-bottom: 30px"
      >
        <template #head(selection)="data">
          <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                           style="margin-top: 7px"
                           @input="val=>toggleAllRow(val)" inline
          ></b-form-checkbox>
        </template>


        <template #cell(registrationTime)="data">
          {{ data.value| momentDateTime }}
        </template>
        <template #cell(realType)="data">
        <span style="font-size:12px" :style="{ color: data.value === 'REAL' ? '#4ebd52' : '#c53838' }"
        >{{
            data.value.toLowerCase()
                .charAt(0)
                .toUpperCase() + data.value.toLowerCase()
                .slice(1)
          }}</span>
        </template>
        <template v-if="!isClientTable" #cell(amount)="data">
          <div class="d-flex justify-content-center align-items-center text-center">
            <span :style="{ color: data.value > 0 ? 'green' : 'red' }">{{ convertToEURO(data.value) }}</span>
          </div>
        </template>
        <template v-else #cell(amount)="data">

          <span :style="{ color: data.value > 0 ? 'green' : 'red' }">{{ convertToEURO(data.value) }}</span>

        </template>
        <template #cell(type)="data">
          <b-badge :variant="getStatusVariant(data.value)">{{ data.value }}</b-badge>
        </template>

        <template #cell(actions)="data">

          <div class="btn-group">
            <b-button class="btn btn-info" size="sm" @click="openCommentModal(data.item)" v-b-tooltip.hover
                      title="Comment"
            >
              <i class="fa fa-sticky-note"></i>
            </b-button>

            <b-button v-if="data.item.status!=='DECLINED' && data.item.status!=='APPROVED'"
                      class="btn btn-success" size="sm" @click="handleMonetaryEvent(data.item.id,'approve')"
                      v-b-tooltip.hover
                      title="Approve"
            >
              <i class="fa fa-check"></i>
            </b-button>
            <b-button v-if="data.item.status!=='DECLINED' && data.item.status!=='APPROVED'"
                      class="btn btn-warning" size="sm" @click="handleMonetaryEvent(data.item.id,'fake')"
                      v-b-tooltip.hover
                      title="Fake Approve"
            >
              <i class="fa fa-check-circle"></i>
            </b-button>
            <b-button v-if="data.item.status!=='DECLINED' && data.item.status!=='APPROVED'"
                      class="btn btn-danger" size="sm" @click="handleMonetaryEvent(data.item.id,'decline')"
                      v-b-tooltip.hover
                      title="Decline"
            >
              <i class="fa fa-times"></i>
            </b-button>
            <b-button v-if="data.item.status==='DECLINED'"
                      class="btn btn-danger" size="sm" @click="handleMonetaryEvent(data.item.id,'delete')"
                      v-b-tooltip.hover
                      title="Delete"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </div>

        </template>
        <template #cell(paymentDetails.creditCardNumber)="data">
          <template v-if="data.item.paymentDetails!=null && data.item.paymentDetails.creditCardNumber!=null">
              <span class="mr-1">Card: {{ data.value }}</span>
              <span class="mr-1">Exp: {{ data.item.paymentDetails.creditCardExp }}</span>
              <span class="">CVV: {{ data.item.paymentDetails.creditCardCvv }}</span>
          </template>
        </template>
        <template #cell(paymentDetails)="data">
          <span>{{ data.value['processorName'] }}</span>
        </template>
        <template #cell(selection)="data">
          <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
          ></b-form-checkbox>
        </template>


      </b-table>


      <!--{{pagination}}-->
      <div class="mt-2 text-center">
        <div class="d-inline-block">
          <b-pagination
              v-model="pagination.page"
              v-bind:total-rows="pagination.total"
              v-bind:per-page="pagination.size"
              @page-click="onPageClick"
              align="center"
          ></b-pagination>
        </div>
        <div class="d-inline-block">
          <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
            <b-select-option v-bind:value="10">10</b-select-option>
            <b-select-option v-bind:value="25">25</b-select-option>
            <b-select-option v-bind:value="50">50</b-select-option>
            <b-select-option v-bind:value="100">100</b-select-option>
          </b-select>
          /{{ pagination.total }} {{ $t('columns.counterNames.monetaries') }}

        </div>

      </div>
    </div>
    <monetary-account-modal ref="monetary-account-modal"/>
    <monetary-multi-account-modal ref="monetary-multipleAccount-modal"/>
    <b-modal v-model="isCommentModalVisible" :title="title" @hide="closeCommentModal" ok-variant="success"
             cancel-variant="danger"
             @ok="saveComment" @cancel="closeCommentModal" ok-title="Save" cancel-title="Cancel"
             :cancel-title="$t('operations.cancel')" :ok-title="$t('operations.save')"
    >
      <div class="modal-body">
        <textarea v-model="comment" class="form-control" rows="4"></textarea>
      </div>

    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientCell from '@/components/partial/clients/ClientCell';
import UserSelectBox from '@/components/widget/select/UserSelectBox';
import MonetariesSearchRequest from '@/model/monetary/MonetarySearchRequest';
import MonetaryAccountModal from '@/components/modal/monetaryAccountModal.vue';
import MonetaryMultiAccountModal from '@/components/modal/monetaryMultiAccountModal';

export default {
  name: 'ClientMonetaryTable',
  components: {
    MonetaryAccountModal,
    MonetaryMultiAccountModal,
    ClientCell,
    UserSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    monetaries: [],
    clients: {},
    selectedItem: {}, // Store the selected item
    comment: '',
    isCommentModalVisible: false,
    type: null,
    typeText: 'Total transactions',
    totalTransactions: 0,
    accounts: [],

    filters: MonetariesSearchRequest(),
    selected: { all: false },
    headerColor: '',
    selectedOption: null,
    showDetails: false,

  }),
  props: {
    isClientTable: {
      default: () => false
    },
    clientId: {
      type: String,
      required: true
    },
    columns: {
      default: () => ([]),
      required: true
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    this.filters['clientDetails']['clientId'] = this.clientId;
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),
    hasMultipleAccounts() {
      return Array.isArray(this.accounts) && this.accounts.length > 1;
    },
    fields() {
      let $this = this;
      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.label || column.key}`),
          sortable: true,
          active: column.enable,
        };
      })
          .sort((a, b) => {
            if (a.key === 'id') return -1; // Move 'type' field to the second position
            if (b.key === 'id') return 1;
            return 0;
          });
      fields.push({
        key: 'paymentDetails.creditCardNumber',
        label: 'Credit Card',
        class: 'text-center',
        sortable: true,
        active: true,
      });

      fields.push({
        key: 'paymentDetails.comments',
        label: 'Comment',
        class: 'text-center',
        sortable: false,
        active: true,
      });
      fields.push({
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
        sortable: false,
        active: true,
      });
      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('monetary', ['getAllMonetary', 'updateMonetary', 'handleEvent']),
    ...mapActions('account', ['getAccountsByClient']),
    async handleMonetaryEvent(id, event) {
      const $this = this;

      await this.handleEvent({
        id,
        event
      })
          .then((updatedMonetary) => {
            const monetaryToUpdate = $this.monetaries.find(monetary => monetary.id === updatedMonetary.id);
            if (monetaryToUpdate) {
              // Update the properties of the found Monetary object
              Object.assign(monetaryToUpdate, updatedMonetary);
              $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.monetaries') }));
              $this.$emit('input', $this.monetaries);
            }
          })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), err.response.data.error);
          });
    },
    openCommentModal(item) {
      this.selectedItem = {
        monetary: item,
        client: this.clients[item.clientDetails.clientId]
      }; // Store the selected item
      this.title = this.getTitle();
      this.comment = item.paymentDetails.comments;
      this.isCommentModalVisible = true;
    },
    getTitle() {
      let monetary = { ...this.selectedItem.monetary };
      let client = { ...this.selectedItem.client };
      let title = '';
      if (monetary.clientDetails != null) {
        let accountId = monetary.clientDetails.accountId || '';
        title = accountId + ' ' + client.firstName + ' ' + client.lastName;
      }
      return 'Comment Transaction ' + title;

    },
    async saveComment() {
      let $this = this;
      if (this.selectedItem.monetary.paymentDetails == null) {
        this.selectedItem.monetary.paymentDetails = {};
      }

      this.selectedItem.monetary.paymentDetails.comments = this.comment;
      console.log('Saved Comment for item:');
      await this.updateMonetary({
        monetary: this.selectedItem.monetary,
        id: this.selectedItem.monetary.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.monetaries') }));
      this.$emit('input', this.monetary);
      this.isCommentModalVisible = false;
      this.selectedItem = {}; // Clear the selected item
      this.comment = ''; // Clear the comment field

    },
    openModal() {
      this.$refs['monetary-account-modal'].showModal(this.clientId);
    },
    openBetweenAccountModal() {
      this.$refs['monetary-multipleAccount-modal'].showModal(this.clientId);
    },

    showAllTransactions() {
      this.type = null;
      this.typeText = 'Total transactions';
      this.resetResponse();
      this.refresh();
    },
    showDeposits() {
      this.type = 'DEPOSIT';
      this.typeText = 'Total deposits';
      this.resetResponse();
      this.refresh();
    },
    showWithdraws() {
      this.type = 'WITHDRAWAL';
      this.typeText = 'Total withdrawals';
      this.resetResponse();
      this.refresh();
    },
    resetResponse() {
      this.monetaries = [];
      this.clients = {};
      this.totalTransactions = 0;

    },

    getStatusVariant(status) {
      switch (status) {
        case 'DEPOSIT':
          return 'success';
        case 'CARD':
          return 'warning';
        case 'WITHDRAWAL':
          return 'danger';
        default:
          return 'primary';
      }
    },
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      this.filters['clientDetails']['clientId'] = this.clientId;
      this.filters['type'] = this.type;
      console.log('client monetary refresh');
      const result = await this.getAllMonetary({
        pagination: this.pagination,
        sort,
        filter: this.filters,
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.monetaries = result.data['monetaryTransactions'];
      this.clients = result.data['clients'].reduce((map, obj) => (map[obj.id] = obj, map), {});
      this.totalTransactions = this.monetaries.reduce((total, item) => total + item.amount, 0);
      this.accounts = await this.getAccountsByClient(this.clientId);
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    },
    reGenerateAccounts(accounts) {
      return accounts.map(account => {
        const label = account.isDemo ? `${account.id} Demo-` : `${account.id} Real-`;
        const displayLabel = `${label} ${account.balance} ${account.currency}`;
        return {
          ...account,
          displayLabel
        }; // Spread account fields and add displayLabel
      });
    },
  },

};
</script>

<style scoped>

</style>
